@use "buttons";
@use "breakpoints";
@use "colors";

.skip-container {
  color: colors.$dark-gray;
  display: flex;
  flex-direction: column;
  justify-items: start;
  margin-block-start: 32px;

  @media (min-width: breakpoints.$lg) {
    flex-direction: row;
    margin-block-start: 48px;
  }
}

.cta-button-light {
  background-color: transparent;
  border: none;
  color: colors.$black;
  font-weight: bold;
  inline-size: fit-content;
  padding: 0;
  text-decoration: underline;
}

.submit-container {
  background-color: colors.$white;
  border-block-start: 1px solid colors.$light-gray;
  inline-size: 100%;
  inset-block-end: 0;
  inset-inline-end: 0;
  margin-block-start: 0;
  padding: 0 24px;
  position: fixed;

  @media (min-width: breakpoints.$md) {
    inline-size: 50%;
    padding: 0 120px;
  }
}

.unselected-time-container {
  align-items: center;
  background-color: colors.$light-gray;
  display: flex;
  flex-direction: row;
  inline-size: 100%;
  margin-block-start: 16px;
  padding: 8px 12px;

  @media (min-width: breakpoints.$md) {
    padding: 8px 16px;
  }
}

.error-icon-svg {
  inline-size: 16px;
  vertical-align: top;
}

.error-icon-text {
  font-size: 14px;
  line-height: 140%;
  margin: 3px 0 0 10px;
}

.cta {
  background-color: colors.$white;
  display: flex;
  flex-direction: column;
  inset-block-end: 0;
  inset-inline: 0;
  justify-content: left;
  padding: 16px 0 24px;
  z-index: 6;

  @media (min-width: breakpoints.$sm) {
    block-size: 100px;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 0 0;
  }

  @media (min-width: breakpoints.$md) {
    background-color: colors.$white;
    block-size: 120px;
    flex-direction: row;
    inset-inline-start: 50vw;
    justify-content: start;
    padding: 24px 0 0;
  }
}

.cta-button {
  @include buttons.button(buttons.$theme-primary-dark, buttons.$width-wide);
}
