@use "breakpoints";
@use "buttons";
@use "colors";
@use "typography";

// #PLEASE NOTE# - The styles in the module are all
// mobile-first unlike most of the styles in the project.

.introduction {
  display: none;
  margin-block-end: 4.8rem;
  text-align: center;
}

.title {
  @include typography.medium-headline;
}

.description {
  @include typography.subhead-semi-bold;

  font-weight: 400 !important;
  margin-block-start: 0.8rem;
}

.eqx-form {
  display: flex;
  flex-flow: row wrap;
}

.banner-container {
  inline-size: 100%;
}

.no-online-join-message {
  @include typography.body-small;

  color: colors.$gray;
  margin-block-start: 10px;
}

.form-field {
  /* stylelint-disable-next-line selector-class-pattern */
  :global .MuiInputBase-root {
    margin: 0;
  }
}

.field-first-name {
  flex: 0 0 calc(50% - 1.2rem);
  margin-inline-end: 2.4rem;
}

.field-last-name {
  flex: 0 0 calc(50% - 1.2rem);
}

.field-corporate-email {
  display: flex;
}

.field-zip-code {
  display: none;
}

.legal-text {
  @include typography.body-extra-small;

  color: colors.$dark-gray;
  margin-block-start: 6px;

  a {
    margin-inline-start: 3px;
    text-decoration: underline;
  }
}

.uk-legal-text {
  @extend .legal-text;

  margin-block-end: 46px;
}

.uk-legal-sub-text {
  @extend .legal-text;

  margin-block-start: 46px;
}

.agreement-text {
  @extend .legal-text;

  inline-size: 100%;
  margin-block-start: 16px;
}

.radio-label {
  cursor: pointer;
  display: inline-block;
  inline-size: 100%;
  margin-block: 0;
  margin-inline: 0 12px;

  @media (width >= breakpoints.$sm) {
    inline-size: auto;
  }

  @media (width >= breakpoints.$md) {
    inline-size: 100%;
    margin-inline-end: auto;
  }

  input[type="radio"] {
    display: none;
  }
}

.nearest-clubs-container {
  margin: 15px auto 15px 0;

  .location {
    color: colors.$black;
  }
}

.other-location {
  background-color: transparent;
  border: none;
  display: block;
  margin-block-end: 2em;
  text-decoration: underline;
}

.cta-button-group {
  display: flex;
  flex-direction: column;
  inline-size: 100%;
  justify-content: space-between;
  margin-block: 5rem 0;
  position: relative;

  @media (width >= breakpoints.$sm) {
    flex-direction: row;
  }
}

.cta-button {
  @include buttons.button(buttons.$theme-primary-dark, buttons.$width-wide);

  & + .cta-button {
    margin-block-start: 16px;
  }

  &[data-track="lead-flow:contact-info:join-cta"] {
    display: none;
  }

  @media (width >= breakpoints.$sm) {
    & + .cta-button {
      margin-block-start: 0;
      margin-inline: 16px auto;
    }
  }
}

.error-message {
  color: colors.$error-red;

  a {
    text-decoration: underline;
  }
}

.club-select-container {
  flex: 0 0 100%;
  margin-block: 8px 28px;
  position: relative;

  .club-select-label {
    color: colors.$medium-gray;
    font-size: 1.2rem;
    inset-block-start: 0;
    pointer-events: none;
    position: absolute;
  }

  &[data-blank="true"] {
    .club-select-label {
      color: colors.$black;
      font-size: 1.8rem;
    }
  }
}

.club-name-text {
  @include typography.body;

  border: 1px solid rgba(0, 0, 0, 23%);
  border-radius: 0;
  color: colors.$black;
  cursor: pointer;
  min-block-size: 4.2rem;
  padding: 1.5rem;

  &:hover {
    border: 1px solid rgba(0, 0, 0, 87%);
  }
}

.checkbox-label {
  font-family: inherit;
  margin-block-start: 3.2rem;

  /* stylelint-disable-next-line selector-class-pattern */
  :global .MuiFormControlLabel-label {
    @include typography.body-small;
  }

  @media (width >= breakpoints.$sm) {
    margin-block-start: 4.8rem;
  }
}

.checkbox-label + .legal-text {
  margin-inline-start: 3.3rem;
}

.checkbox {
  color: colors.$black;
  max-inline-size: 330px;

  /* stylelint-disable-next-line selector-class-pattern */
  &:global.Mui-checked {
    color: colors.$black;
  }
}

.checkbox-promo {
  margin-inline-start: 9px;
}

.checkbox-uk {
  color: colors.$black;

  /* stylelint-disable-next-line selector-class-pattern */
  &:global.Mui-checked {
    color: colors.$black;
  }
}

.former-member-redirect-message {
  margin-block-end: 2.4rem;
}

body[data-vwo-web2665] {
  .introduction {
    display: block;
  }
}

body[data-vwo-web3359] {
  .field-corporate-email {
    display: flex;
  }
}

body[data-vwo-web3659="1:34"] {
  .field-corporate-email,
  .field-zip-code {
    display: none;
  }
}

body[data-vwo-web3659="2:34"] {
  .field-corporate-email {
    display: none;
  }

  .field-zip-code {
    display: flex;
  }
}
