@use "breakpoints";
@use "colors";
@use "typography";

/********************************************************************   */

// stylelint-disable-next-line scss/at-mixin-pattern
@mixin _text-link {
  border-block-end-style: solid;
  border-block-end-width: 1.5px;
  display: inline-block;
  margin-inline-end: auto;
  padding-block-end: 3px;
  transition: all 0.2s ease-in;

  &:hover {
    cursor: pointer;
  }

  &[disabled],
  &[aria-disabled="true"] {
    cursor: not-allowed;
  }

  @media (min-width: breakpoints.$lg) {
    border-block-end-width: 2px;
    padding-block-end: 4px;
  }
}

// stylelint-disable-next-line scss/at-mixin-pattern
@mixin _text-link-dark {
  @include _text-link;

  border-color: colors.$black;
  color: colors.$black;

  &:hover {
    border-color: rgba(colors.$black, 0.6);
    color: rgba(colors.$black, 0.6);
  }

  &[disabled],
  &[aria-disabled="true"] {
    border-color: colors.$dark-gray;
    color: colors.$dark-gray;
  }
}

// stylelint-disable-next-line scss/at-mixin-pattern
@mixin _text-link-light {
  @include _text-link;

  border-color: colors.$white;
  color: colors.$white;

  &:hover {
    border-color: rgba(colors.$white, 0.6);
    color: rgba(colors.$white, 0.6);
  }

  &[disabled],
  &[aria-disabled="true"] {
    border-color: colors.$gray;
    color: colors.$gray;
  }
}

@mixin regular-text-link-dark {
  @include typography.cta;
  @include _text-link-dark;
}

@mixin regular-text-link-light {
  @include typography.cta;
  @include _text-link-light;
}

@mixin small-text-link-dark {
  @include typography.cta-small;
  @include _text-link-dark;
}

@mixin small-text-link-light {
  @include typography.cta-small;
  @include _text-link-light;
}

/********************************************************************   */

@mixin arrow-link {
}
