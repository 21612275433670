@use "colors";
@use "breakpoints";

.corporate-flow {
  background-color: colors.$white;
  block-size: 100%;
  color: colors.$black;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  @media (min-width: breakpoints.$sm) {
    block-size: 100%;
    overflow: visible;
  }

  @media (min-width: breakpoints.$md) {
    block-size: 100vh;
    flex-direction: row;
    overflow: hidden;
  }
}

.headline {
  flex: 0 0 100%;

  @media (min-width: breakpoints.$md) {
    flex-basis: 50%;
  }
}

.form-content {
  flex: 0 0 100%;
  scroll-behavior: smooth;

  // the overflow scroll is causing the spinner to size relative to
  // the viewport and not the .form-content, so we add an inner...
  .form-content-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-block-size: 100%;
    padding: 24px;
    position: relative;

    /* stylelint-disable-next-line selector-class-pattern */
    :global .MuiFormControlLabel-root {
      margin-inline: 0;

      /* stylelint-disable */
      .MuiButtonBase-root.MuiCheckbox-root {
        margin-inline-start: 0;
      }
      /* stylelint-enable */
    }
  }

  @media (min-width: breakpoints.$sm) {
    block-size: 100%;
    margin: 0 auto;
    overflow: auto;

    .form-content-inner {
      padding: 52px 24px;
    }
  }

  @media (min-width: breakpoints.$md) {
    block-size: 100vh;
    flex-basis: 50%;
    overflow: hidden auto;

    .form-content-inner {
      padding: 120px 90px;
    }
  }
}
