@use "colors";
@use "breakpoints";

.lead-flow {
  display: flex;
  flex-direction: column;

  [data-is="user-flow:AppointmentSelector"] {
    inline-size: calc(100% + 2.4rem);
    margin-block-start: 3.2rem;

    @media (width >= breakpoints.$md) {
      inline-size: 100%;
      margin-block-start: 4.8rem;
    }
  }

  @media (min-width: breakpoints.$md) {
    flex-direction: row;
  }
}

.headline {
  background-color: colors.$white;
  flex: 0 0 100%;

  @media (min-width: breakpoints.$md) {
    block-size: 100dvh;
    flex-basis: 50%;
    inset-block-start: 0;
    position: sticky;
  }
}

.form-content {
  background-color: colors.$white;
  color: colors.$black;
  flex: 0 0 100%;
  min-inline-size: 0;
  scroll-behavior: smooth;

  // the overflow scroll is causing the spinner to size relative to
  // the viewport and not the .form-content, so we add an inner...
  .form-content-inner {
    min-block-size: 100%;
    padding: 24px;
    position: relative;

    /* stylelint-disable-next-line selector-class-pattern */
    :global .MuiFormControlLabel-root {
      margin-inline: 0;

      /* stylelint-disable-next-line selector-class-pattern */
      .MuiButtonBase-root.MuiCheckbox-root {
        margin-inline-start: 0;
      }
    }
  }

  @media (min-width: breakpoints.$sm) {
    .form-content-inner {
      padding: 52px 24px 100px;
    }
  }

  @media (min-width: breakpoints.$md) {
    flex-basis: 50%;
    min-block-size: 100%;

    .form-content-inner {
      padding: 60px 120px 182px;
    }
  }
}

.loader {
  background: rgba(colors.$black, 40%);
  block-size: 100%;
  inline-size: 100%;
  inset-block-start: 0;
  inset-inline-start: 0;
  margin: 0;
  position: absolute;
  z-index: 10;
}

body[data-vwo-web2665] {
  .lead-flow {
    [data-is="ContactInformation"] {
      [data-is="EqxTextInput"] {
        label {
          font-size: 1.8rem;
        }
      }

      [class^="ContactInformation_club-select-label__"] {
        font-size: 1.4rem;
        padding-inline-start: 2.2rem;
      }

      [class^="ContactInformation_cta-button-group__"] {
        order: 9;
      }

      [class*="ContactInformation_checkbox-label___"] {
        padding-inline-start: 0;
      }

      [class^="ContactInformation_legal-text__"] {
        margin-inline-start: 0;
      }
    }
  }
}

body {
  [data-is="GlobalHeader"] + main {
    > .lead-flow {
      @media (min-width: breakpoints.$md) {
        .headline {
          block-size: calc(100dvh - var(--global-header-height));
          inset-block-start: var(--global-header-height);
        }
      }
    }
  }
}
