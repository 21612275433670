@use "breakpoints";
@use "colors";
@use "typography";

.schedule-appointment {
  inline-size: 100%;
  padding-block-end: 64px;

  @media (min-width: breakpoints.$md) {
    overflow: visible;
    padding-block-end: 0;
  }

  @media (min-width: breakpoints.$lg) {
    block-size: auto;
    overflow: visible;
  }
}

.no-online-join-message {
  @include typography.body-small;

  color: colors.$gray;
  margin-block-start: 10px;
}
