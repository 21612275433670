@use "breakpoints";
@use "colors";
@use "typography";

.appointment-selector {
  font-size: 16px;
  list-style: none;
  margin-block-end: 0;
  overflow: hidden;
  padding-inline: 0;
  position: relative;
}

.appointments-block {
  + .appointments-block {
    margin-block-start: 3.2rem;
  }
}

.appointments-date {
  @include typography.body;

  display: block;
  margin: 0 0 6px;
}

.appointments {
  display: flex;
  flex-flow: row wrap;
  list-style: none;
  margin-block: 0;
  overflow-x: auto;
  padding-inline: 0;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  white-space: nowrap;
}

.appointment {
  block-size: 4.8rem;
  flex: 0 0 auto;
  inline-size: 8.5rem;
  scroll-snap-align: start;

  &:has(+ .appointment) {
    margin-block-end: 1.1rem;
    margin-inline-end: 1.1rem;
  }
}

.appointment button {
  @include typography.body-small;

  align-items: center;
  background-color: colors.$white;
  block-size: 100%;
  border: 1px solid colors.$gray;
  cursor: pointer;
  display: flex;
  inline-size: 100%;
  justify-content: center;
  position: relative;
  transition:
    border,
    opacity,
    background-color,
    color 0.2s ease-in-out;
  user-select: none;
  vertical-align: center;

  &[aria-current="true"] {
    background-color: colors.$black;
    color: colors.$white;
    cursor: default;
    opacity: 1;
    pointer-events: none;
  }

  &[disabled] {
    border: 1px solid colors.$gray;
    color: colors.$black;
    cursor: not-allowed;
    overflow: hidden;

    &::before {
      background-color: colors.$gray;
      clip-path: polygon(0 calc(100% - 1px), 100% 0, 100% 1px, 0 100%);
      content: "";
      inset: 0;
      position: absolute;
    }
  }

  &:hover:not([disabled]) {
    @media (width >= breakpoints.$sm) {
      background-color: colors.$dark-gray;
      color: colors.$white;
      opacity: 1;
    }
  }
}
